<template>
  <div v-if="user" class="main-card" a-tag="exchangeCard">
    <div v-if="user.exchanges.data.length">
      <leader-exchange
        v-for="(exchange, index) in user.exchanges.data"
        :key="index"
        :exchange="exchange"
        @openRemoveExhangeModal="openRemoveExhangeModal"
      />
    </div>
    <empty v-else :title="$t('MY_PROFILE.NO_EXCHANGES')" />

    <!-- confirmation modal -->
    <confirmation-modal
      name="confirmation-leader-modal"
      :title="confirmation.title"
      :message="confirmation.message"
      :showDangerImage="confirmation.showDangerImage"
      :isConfirmationLoading="isConfirmationLoading"
      @onConfirm="onConfirm"
      @onCancel="resetConfirmationModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LeaderExchange from "@/components/Application/Leaders/LeaderExchange";
import { deleteExchangeRequest } from "@/api/leaders";
import Empty from "@/components/Shared/Empty";

export default {
  components: {
    LeaderExchange,
    Empty,
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  data() {
    return {
      confirmation: {
        title: "",
        message: "",
        showDangerImage: true,
      },
      isConfirmationLoading: false,
      selectedExchange: null,
    };
  },
  methods: {
    ...mapActions(["SetUser"]),

    // exchange
    openRemoveExhangeModal(exchange) {
      this.confirmation = {
        title: this.$t("LEADERS.REMOVING_EXCHANGE"),
        message: this.$t("LEADERS.ARE_YOU_REMOVING_EXCHANGE"),
        showDangerImage: true,
      };
      this.$bvModal.show("confirmation-leader-modal");
      this.selectedExchange = exchange;
    },

    onConfirm() {
      const body = {
        id: this.selectedExchange.id,
      };

      this.isConfirmationLoading = true;

      this.ApiService(deleteExchangeRequest(body))
        .then(() => {
          const params = {
            token: this.token,
          };
          this.SetUser(params);
          this.resetConfirmationModal();
        })
        .finally(() => {
          this.isConfirmationLoading = false;
        });
    },

    resetConfirmationModal() {
      this.$bvModal.hide("confirmation-leader-modal");
      this.confirmation = {
        title: "",
        message: "",
        showDangerImage: true,
      };
      this.isConfirmationLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
